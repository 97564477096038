import { Grid } from '@mantine/core';
import React from 'react';

function Portfolio() {
    return (
        <Grid>
            <Grid.Col span={9}>
                Heyo
            </Grid.Col>
            <Grid.Col span={3}>
                later
            </Grid.Col>
        </Grid>
    );
}

export default Portfolio;